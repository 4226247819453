import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const logRequest = (serviceName, commandName, ...args) => {
  // eslint-disable-next-line no-console
  console.log(
    `%c ${serviceName} %c ${commandName} %c`,
    'background:#21a0e2 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
    'background:#89cd4d ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
    'background:transparent',
    ...args
  )
}

const baseUrl = '/api/controltower/takeUpPremiumDiscountManagement'

const getResponseFilename = (response) => {
  const headerPart = response.headers['content-disposition'].split("''")
  const filename =
    Array.isArray(headerPart) && headerPart.length > 1 ? headerPart[1] : null
  return decodeURIComponent(filename)
}

const service = createCrudService(baseUrl, api, {
  importLogUrl: '/api/controltower/importLog'
})

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.contractReference.options = ctx.contractReferenceOptions
  fieldsById.hviAndGradePdReferenceTable.options =
    ctx.hviAndGradePdReferenceTableOptions
  fieldsById.contaminationPdReferenceTable.options =
    ctx.contaminationPdReferenceTableOptions
  fieldsById.pdCurrency.options = ctx.pdCurrencyOptions
}

service.previewWeightedAverage = async (queryParams) => {
  logRequest('P/D', 'previewWeightedAverage', queryParams)
  try {
    const endPoint = queryParams
      ? `${baseUrl}/weightedAveragePreview?${queryParams}`
      : `${baseUrl}/weightedAveragePreview`
    const response = await api.get(endPoint)
    return response.data
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.applyWeightedAverage = async (queryParams) => {
  logRequest('P/D', 'applyWeightedAverage', queryParams)
  try {
    const endPoint = queryParams
      ? `${baseUrl}/applyWeightedAverage?${queryParams}`
      : `${baseUrl}/applyWeightedAverage`
    await api.put(endPoint)
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.clearWeightedAverage = async (queryParams) => {
  logRequest('P/D', 'clearWeightedAverage', queryParams)
  try {
    const endPoint = queryParams
      ? `${baseUrl}/clearWeightedAverage?${queryParams}`
      : `${baseUrl}/clearWeightedAverage`
    await api.put(endPoint)
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.validateFieldsWithStatusChange = async (entities) => {
  logRequest('P/D', 'validateFieldsWithStatusChange')
  try {
    const response = await api.post(
      `${baseUrl}/validateFieldsWithStatusChange`,
      entities
    )
    return response.data
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.validateAlert = async (entities) => {
  logRequest('P/D', 'validationAlert')
  try {
    const response = await api.post(`${baseUrl}/validationAlert`, entities)
    return response.data
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.exportProducer = async (queryParams) => {
  logRequest('P/D', 'exportProducer', queryParams)

  try {
    const endPoint = queryParams
      ? `${baseUrl}/exportProducer?${queryParams}`
      : `${baseUrl}/exportProducer`
    const response = await api.get(endPoint, { responseType: 'blob' })
    const filename = getResponseFilename(response)

    return { data: response.data, filename }
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.calculatePremiumDiscountById = async (id) => {
  logRequest('P/D', 'calculatePremiumDiscountById', id)

  try {
    const response = await api.put(`${baseUrl}/calculatePremiumDiscount/${id}`)
    return response?.data
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.calculatePremiumDiscountByFilter = async (queryParams) => {
  logRequest('P/D', 'calculatePremiumDiscountByFilter', queryParams)

  try {
    const endPoint = queryParams
      ? `${baseUrl}/calculatePremiumDiscountByFilter?${queryParams}`
      : `${baseUrl}/calculatePremiumDiscountByFilter`
    const response = await api.post(endPoint)
    return response?.data
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.hviExtract = async (tabName) => {
  try {
    const tabNameQuery = `tabName=${tabName}`
    const filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `${service.lastAppliedFilter}`
    const url = filter
      ? `${baseUrl}/hviExtract?${filter}&${tabNameQuery}`
      : `${baseUrl}/hviExtract?${tabNameQuery}`
    const response = await api.get(url, {
      responseType: 'blob'
    })
    const filename = getResponseFilename(response)

    return { data: response.data, filename }
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

export default service
